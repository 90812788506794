<template>
  <NuxtLayout name="default" :header="header" :footer="footer">
    <StoryblokComponent :blok="story.content" :story="story" />
  </NuxtLayout>
</template>

<script setup>
import SimpleHeader from "@/components/SimpleHeader.vue";
import GlobalHeader from "@/components/GlobalHeader.vue";
import SimpleFooter from "@/components/SimpleFooter.vue";
import GlobalFooter from "@/components/GlobalFooter.vue";
import { useSettings } from "../composables/MicroCopy";

const route = useRoute();
const config = useRuntimeConfig();

let story;

if (route.path.replace(/\/$/, "") === "/home") {
  throw createError({
    statusCode: 404,
  });
}

const countryPrefix = config.public["storyblokCountryPrefix"];

const path = computed(() => {
  const [facilityType, region, facility, hub, news] = route.params.slug;
  if (route.path === "/") {
    return `${countryPrefix}/home`;
  } else {
    return `${countryPrefix}${route.path}`;
  }
});

try {
  story = await useAsyncStoryblok(path.value, {
    version: config.public["storyblokVersion"],
    resolve_relations: [
      "facility-overview.additional_links",
      "page-landing-event.facility",
      "faq-list.items",
      "collection-news.folder_name",
      "collection-news.facility_news_folder_name",
    ].join(","),
    resolve_links: "url",
  });
} catch (error) {
  const parsedError = JSON.parse(error);
  const statusMessage =
    parsedError.status === 404
      ? `Story with path "${path.value}" not found"`
      : parsedError.message;

  if (process.client) {
    showError({ message: statusMessage, statusCode: parsedError.status });
    window.location.reload(true);
  }

  throw createError({
    statusCode: parsedError.status,
    statusMessage,
  });
}

const header = computed(() =>
  story.value.content.component === "page-landing-event"
    ? SimpleHeader
    : GlobalHeader
);

const footer = computed(() =>
  story.value.content.component === "page-landing-event"
    ? SimpleFooter
    : GlobalFooter
);

definePageMeta({ layout: false });


const metaTitle = useSettings(`metatitle-${story.value.content.facilityTypeGroupKey}`)
const overwriting_metatitle = useSettings(`overwriting-metatitle`)
if (metaTitle && overwriting_metatitle === 'true') {
  story.value.content.og_title = metaTitle.replace(/\[(\w+)\]/g, (_, key) => story.value.content[key] || "")
}

useCustomSeoMeta(story.value.content);
</script>
